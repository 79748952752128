<template>
  <div class="m1">
    <div class="m1-h">
      <h2>用户列表</h2>
      <div class="m1-form">
        <el-input v-model="form.key_id" style="width:169px; margin-right: 34px;" placeholder="请输入用户ID"></el-input>
        <el-input v-model="form.phone" style="width:169px; margin-right: 34px;" placeholder="请输入手机号"></el-input>
        <el-select v-model="form.status" style="width:169px; margin-right: 34px" placeholder="请选择活动区域">
          <el-option label="全部用户状态" value=""></el-option>
          <el-option label="正常" value="0"></el-option>
          <el-option label="异常" value="3"></el-option>
          <el-option label="封禁" value="1"></el-option>
        </el-select>
        <el-select v-model="form.submit_status" style="width:169px" placeholder="请选择求职状态">
          <el-option label="全部求职状态" value=""></el-option>
          <el-option label="未提交" value="0"></el-option>
          <el-option label="已提交" value="1"></el-option>
        </el-select>
      </div>
      <div class="m1-btns">
        <el-button type="primary" @click="getList()">查询</el-button>
        <el-button plain type="primary" @click="changeStatus()">批量封禁</el-button>
        <el-button plain type="primary" @click="reset()">重置</el-button>
      </div>
    </div>
    <el-table
      border
      :data="data.data"
      @selection-change="handleSelectionChange"
      style="width: 100%">
      <el-table-column
      type="selection"
      width="55">
    </el-table-column>
      <el-table-column
        prop="key_id"
        label="用户ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="nick_name"
        label="用户昵称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="手机号">
      </el-table-column>
      <el-table-column
        prop="point"
        label="积分">
      </el-table-column>
      <el-table-column
        prop="status"
        label="用户状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">正常</span>
          <span v-if="scope.row.status == 1" style="color: red">禁用</span>
          <span v-if="scope.row.status == 3" style="color: red">异常</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="lock_time"
        label="锁定时间至">
        <template slot-scope="scope">
          <span v-if="scope.row.lock_time && scope.row.status == 3" style="color: red">{{scope.row.lock_time}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="submit_status"
        label="求职状态">
        <template slot-scope="scope">
          <span v-if="scope.row.submit_status == 0">未提交</span>
          <span v-else-if="scope.row.submit_status == 1">已提交</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作">
        <template slot-scope="scope">
          <a @click="toDetail(scope.row)" href="javascript:;" style="margin-right: 10px">详情</a>
          <a @click="blocked(scope.row)" v-if="scope.row.status == 1" href="javascript:;">解禁</a>
          <a @click="blocked(scope.row)" v-else href="javascript:;">封禁</a>
        </template>
      </el-table-column>
    </el-table>
    <div class="m1-r1">
      <div>共<span class="m1-text1">{{data.total}}</span>条记录</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getList()"
          :current-page.sync="form.page"
          :total="data.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  list,save,status
} from '@/api/user';
export default {
  name: 'index',
  data() {
    return {
      multipleSelection: [],
      form: {
        page: 1,
        page_size: 10,
        key_id: '',
        phone: '',
        status: '',
        submit_status: ''
      },
      data: {},
      editForm: {
        id: '',
        status: ''
      },
      multipleSelection: []
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList () {
      list(this.form).then(res =>{
        this.data = res.data.data
      })
    },
    reset () {
      this.form.key_id = ''
      this.form.phone = ''
      this.form.status = ''
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    toDetail (item) {
      window.open('user/detail?id=' + item.id)
    },
    toSave () {
      save(this.editForm).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    blocked (item) {
      this.editForm.id = item.id; 
      this.editForm.status = item.status == 1 ? 0 : 1;
      this.toSave()
    },
    changeStatus () {
      let ids = [];
      this.multipleSelection.forEach((item) => {
        ids.push(item.id)
      })
      status({
        ids: ids
      }).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
</style>
