import http from './http'

export const list = (params) => http.get(`user/list`,params)
export const detail = (params) => http.get(`user/detail`,params)
export const save = (params) => http.post(`user/save`,params)
export const calllist = (params) => http.get(`call/list`,params)
export const callResumeList = (params) => http.get(`call/resume/list`,params)
export const resumeDetail = (params) => http.get(`user/resume/detail`,params)
export const resumeSave = (params) => http.post(`user/resume/save`,params)
export const pointSave = (params) => http.post(`point/save`,params)
export const pointDetail = (params) => http.get(`point/detail`,params)
export const pointList = (params) => http.get(`point/list`,params)
export const status = (params) => http.post(`user/status`,params)
export const areaLists = (params) => http.get(`area/lists`,params)
export const sub = (params) => http.get(`job/type/sub`,params)